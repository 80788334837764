import React, { Component } from "react"

import Hero from "./Hero/Hero"
import Projects from "./Projects/Projects"
import Skills from "./Skills/Skills"
import Footer from "./Footer/Footer"
import History from "./History/History"

export default class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Hero></Hero>
        <Projects></Projects>
        <Skills></Skills>
        <History></History>
        <Footer></Footer>
      </React.Fragment>
    )
  }
}

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import {
  SiGatsby,
  SiReact,
  SiNodedotjs,
  SiCss3,
  SiJavascript,
  SiMongodb,
  SiPython,
  SiRedux,
  SiCsharp,
  SiCplusplus,
} from "react-icons/si"
import { FaSass, FaHtml5 } from "react-icons/fa"
import { ImGit } from "react-icons/im"
import { DiRuby, DiJava } from "react-icons/di"

const Skills = () => {
  return (
    <section id="skills">
      <Container>
        <h2 className="section-title">Tools Used</h2>
        <Row>
          <Col>
            <div className="logo-card gatsby-card">
              <SiGatsby></SiGatsby>
              <p>Gatsby</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card react-card">
              <SiReact></SiReact>
              <p>React</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card node-card">
              <SiNodedotjs></SiNodedotjs>
              <p>Node.js</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card mongo-card">
              <SiMongodb></SiMongodb>
              <p>MongoDB</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card redux-card">
              <SiRedux></SiRedux>
              <p>Redux</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="logo-card js-card">
              <SiJavascript></SiJavascript>
              <p>JavaScript</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card python-card">
              <SiPython></SiPython>
              <p>Python</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card html-card">
              <FaHtml5></FaHtml5>
              <p>HTML 5</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card css-card">
              <SiCss3></SiCss3>
              <p>CSS3</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card sass-card">
              <FaSass></FaSass>
              <p>SASS</p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="logo-card ruby-card">
              <DiRuby></DiRuby>
              <p>Ruby</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card csharp-card">
              <SiCsharp></SiCsharp>
              <p>C#</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card cplusplus-card">
              <SiCplusplus></SiCplusplus>
              <p>C++</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card java-card">
              <DiJava></DiJava>
              <p>Java</p>
            </div>
          </Col>
          <Col>
            <div className="logo-card git-card">
              <ImGit></ImGit>
              <p>Git</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Skills

import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Rotate from "react-reveal/Rotate"
import { StaticImage } from "gatsby-plugin-image"
import Typist from "react-typist"
import Particle from "../Particle/Particle"
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa"
import { SiGitlab } from "react-icons/si"

const Hero = () => {
  return (
    <section id="hero">
      <Particle position="hero" />
      <Container>
        <Row>
          <Col className="col-12 col-sm-6  col-xl-9">
            <h1 className="hero-title">
              <Typist
                startDelay={300}
                cursor={{
                  show: true,
                  hideWhenDone: true,
                  hideWhenDoneDelay: 1000,
                }}
              >
                {"Helol"}
                <Typist.Backspace count={2} delay={100} />
                {"lo, World!"}
                <br />
                {"I'm "}
                <span className="lighter-text">{"Alain Suarez."}</span>
                <br />
                {"I "}
                {"create websites."}
                <Typist.Backspace count={16} delay={500} />
                {"help solve hard problems."}
                <Typist.Backspace count={25} delay={500} />
                {"engineer software solutions."}
              </Typist>
            </h1>
          </Col>
          <Col className="col-12 col-sm-6 col-xl-3">
            <Rotate top right delay={1000}>
              <StaticImage
                className="hero-image"
                src="../../images/Hero_Image_400x400.jpg"
                width={300}
                quality={95}
                formats={["auto", "webp"]}
                alt="Alain Suarez"
                style={{ marginBottom: `1.45rem` }}
              />
            </Rotate>
          </Col>
        </Row>
        <Row className="hero-btn-container">
          <Col className="col-6 col-sm-5 col-md-3 col-xl-2 py-0 px-0">
            <Fade left duration={500} delay={1000} distance="30px">
              <p className="hero-cta">
                <a href="#projects" className="cta-btn cta-btn-hero">
                  {"Projects"}
                </a>
              </p>
            </Fade>
          </Col>
          <Col className="col-6 col-sm-7 col-md-9 col-xl-10 py-0 px-0">
            <Fade left duration={500} delay={1000} distance="30px">
              <p className="hero-cta">
                <a
                  target="blank"
                  rel="noopener noreferrer"
                  href="https://drive.google.com/file/d/1ALRNhRZWsaODXAFNiM4IfBjKTx7Uz9OU/view"
                  className="cta-btn cta-btn-hero"
                >
                  {"Resume"}
                </a>
              </p>
            </Fade>
          </Col>
          <Col className="col-6 col-sm-7 col-md-9 col-xl-10 py-0 px-0">
            <Fade left duration={500} delay={1000} distance="30px">
              <div className="social-links">
                <a
                  key="1"
                  href="https://github.com/VacuusUmbra"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="github"
                  className="bounce-up"
                >
                  <FaGithub />
                </a>
                <a
                  key="2"
                  href="https://gitlab.com/asuar"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="gitlab"
                  className="bounce-up"
                >
                  <SiGitlab />
                </a>
                <a
                  key="3"
                  href="https://linkedin.com/in/alain-suarez"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="linkedin"
                  className="bounce-up"
                >
                  <FaLinkedin />
                </a>
                <a
                  key="4"
                  href="https://twitter.com/AlainSuarezDev"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="twitter"
                  className="bounce-up"
                >
                  <FaTwitter />
                </a>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Hero

import * as React from "react"
import App from "../components/App"
import Seo from "../components/seo"
import "../style/main.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-typist/dist/Typist.css"

const IndexPage = () => {
  return (
    <React.Fragment>
      {" "}
      <Seo title="Alain Suarez" />
      <App></App>
    </React.Fragment>
  )
}
export default IndexPage

import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"

const History = () => {
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  const IMAGE_DELAY = 1000
  const TEXT_DELAY = 500
  const FADE_DURATION = 1000
  const FADE_DISTANCE = "30px"
  const IMAGE_QUALITY = 95

  const TEXT_COL_SM = 12
  const TEXT_COL_LG = 8
  const IMAGE_COL_SM = 12
  const IMAGE_COL_LG = 4

  return (
    <section id="history">
      <Container>
        <div className="content-wrapper">
          <Fade
            bottom
            duration={FADE_DURATION}
            delay={TEXT_DELAY}
            distance={FADE_DISTANCE}
          >
            <h2 className="section-title">{"EXPERIENCE"}</h2>
          </Fade>
          <StaticQuery
            query={graphql`
              query HistoryItemsQuery {
                allHistoryItemsJson {
                  edges {
                    node {
                      id
                      company
                      position
                      startDate
                      endDate
                      description1
                      description2
                      description3
                      description4
                      technologies
                      imageSrc {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => (
              <>
                {data.allHistoryItemsJson.edges.map(jobInfo => {
                  const {
                    id,
                    company,
                    position,
                    startDate,
                    endDate,
                    description1,
                    description2,
                    description3,
                    description4,
                    technologies,
                    imageSrc,
                  } = jobInfo.node
                  return (
                    <Row
                      className="history-info-row"
                      key={"history-info-row-" + id}
                    >
                      <Col lg={TEXT_COL_LG} sm={TEXT_COL_SM}>
                        <Fade
                          left={isDesktop}
                          bottom={isMobile}
                          duration={FADE_DURATION}
                          delay={TEXT_DELAY}
                          distance={FADE_DISTANCE}
                        >
                          <div className="content-wrapper__text">
                            <h3 className="content-wrapper__text-title history-title">
                              {position}
                            </h3>
                            <Row className="company-info-row">
                              <Col>
                                <span className="content-wrapper__text company-title">
                                  {company}
                                </span>
                              </Col>
                              <Col className="date-col">
                                <span>{startDate + " - " + endDate}</span>
                              </Col>
                            </Row>
                            <ul>
                              {description1 && <li>{description1}</li>}
                              {description2 && <li>{description2}</li>}
                              {description3 && <li>{description3}</li>}
                              {description4 && <li>{description4}</li>}
                            </ul>
                            <h4 className="content-wrapper__text tech-title">
                              Technologies Used:
                            </h4>
                            {technologies.map((technology, index) => {
                              return (
                                <span key={"technology-" + index}>
                                  {technology}
                                  {index < technologies.length - 1 && ", "}
                                </span>
                              )
                            })}
                          </div>
                        </Fade>
                      </Col>
                      <Col lg={IMAGE_COL_LG} sm={IMAGE_COL_SM}>
                        <Fade
                          right={isDesktop}
                          bottom={isMobile}
                          duration={FADE_DURATION}
                          delay={IMAGE_DELAY}
                          distance={FADE_DISTANCE}
                        >
                          <div className="content-wrapper__image">
                            <GatsbyImage
                              image={imageSrc.childImageSharp.gatsbyImageData}
                              alt="Project"
                              quality={IMAGE_QUALITY}
                              className="thumbnail rounded"
                              style={{ marginBottom: `1.45rem` }}
                            />
                          </div>
                        </Fade>
                      </Col>
                    </Row>
                  )
                })}
              </>
            )}
          />
        </div>
      </Container>
    </section>
  )
}

export default History

import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import { SiGitlab } from "react-icons/si"
import { FaPlay } from "react-icons/fa"

const Projects = () => {
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true)
      setIsMobile(false)
    } else {
      setIsMobile(true)
      setIsDesktop(false)
    }
  }, [])

  const IMAGE_DELAY = 1000
  const TEXT_DELAY = 500
  const FADE_DURATION = 1000
  const FADE_DISTANCE = "30px"
  const IMAGE_QUALITY = 95

  const TEXT_COL_SM = 12
  const TEXT_COL_LG = 4
  const IMAGE_COL_SM = 12
  const IMAGE_COL_LG = 8

  return (
    <section id="projects">
      <Container>
        <div className="content-wrapper">
          <Fade
            bottom
            duration={FADE_DURATION}
            delay={300}
            distance={FADE_DISTANCE}
          >
            <h2 className="section-title">{"Projects"}</h2>
          </Fade>

          <StaticQuery
            query={graphql`
              query ProjectItemsQuery {
                allProjectItemsJson {
                  edges {
                    node {
                      id
                      title
                      description
                      liveSrc
                      gitSrc
                      imageSrc {
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                          )
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              const getDescription = (title, description, liveSrc, gitSrc) => {
                return (
                  <Col lg={TEXT_COL_LG} sm={TEXT_COL_SM}>
                    <Fade
                      left={isDesktop}
                      bottom={isMobile}
                      duration={FADE_DURATION}
                      delay={TEXT_DELAY}
                      distance={FADE_DISTANCE}
                    >
                      <div className="content-wrapper__text project-text">
                        <h3 className="content-wrapper__text-title">{title}</h3>
                        <div>
                          <p>{description}</p>
                          <p className="mb-4">{""}</p>
                        </div>
                        {liveSrc && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cta-btn cta-btn-hero"
                            href={liveSrc}
                          >
                            <FaPlay /> See Live
                          </a>
                        )}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main source-code"
                          href={gitSrc}
                        >
                          <SiGitlab /> Source Code
                        </a>
                      </div>
                    </Fade>
                  </Col>
                )
              }

              const getImage = (image, title, liveSrc, gitSrc) => {
                return (
                  <Col lg={IMAGE_COL_LG} sm={IMAGE_COL_SM}>
                    <Fade
                      right={isDesktop}
                      bottom={isMobile}
                      duration={FADE_DURATION}
                      delay={IMAGE_DELAY}
                      distance={FADE_DISTANCE}
                    >
                      <div className="content-wrapper__image">
                        <a
                          href={liveSrc || gitSrc}
                          target="_blank"
                          aria-label="Project Link"
                          rel="noopener noreferrer"
                        >
                          <GatsbyImage
                            image={image}
                            alt={title}
                            quality={IMAGE_QUALITY}
                            style={{
                              marginBottom: `1.45rem`,
                              maxWidth: `30em`,
                            }}
                            className="content-image thumbnail rounded"
                          />
                        </a>
                      </div>
                    </Fade>
                  </Col>
                )
              }

              return (
                <>
                  {data.allProjectItemsJson.edges.map((projectInfo, index) => {
                    const {
                      id,
                      title,
                      description,
                      liveSrc,
                      gitSrc,
                      imageSrc,
                    } = projectInfo.node

                    if (index % 2 === 0) {
                      return (
                        <Row className="project-info-row" key={id}>
                          {getDescription(title, description, liveSrc, gitSrc)}
                          {getImage(
                            imageSrc.childImageSharp.gatsbyImageData,
                            title,
                            liveSrc,
                            gitSrc
                          )}
                        </Row>
                      )
                    } else {
                      return (
                        <Row className="project-info-row" key={id}>
                          {getImage(
                            imageSrc.childImageSharp.gatsbyImageData,
                            title,
                            liveSrc,
                            gitSrc
                          )}
                          {getDescription(title, description, liveSrc, gitSrc)}
                        </Row>
                      )
                    }
                  })}
                </>
              )
            }}
          />
        </div>
      </Container>
    </section>
  )
}

export default Projects

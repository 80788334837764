import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-scroll"
import { FaChevronUp, FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa"
import { SiGitlab } from "react-icons/si"
import Fade from "react-reveal"
import Particle from "../Particle/Particle"

const Footer = () => {
  const TEXT_DELAY = 800
  const FADE_DURATION = 1000
  const FADE_DISTANCE = "30px"

  return (
    <footer>
      <Particle position="footer" />
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <FaChevronUp className="up-arrow bounce-up"></FaChevronUp>
          </Link>
        </span>
        <h2 className="contact-wrapper__text text-center">
          {"Looking to hire me?"}
        </h2>
        <Row className="contact-btn-container">
          <Col className="col-6">
            <Fade
              bottom
              duration={FADE_DURATION}
              delay={TEXT_DELAY}
              distance={FADE_DISTANCE}
            >
              <a
                target="blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn-contact"
                href={"mailto:alain.s.compsc@gmail.com"}
              >
                {"Email"}
              </a>
            </Fade>
          </Col>
          <Col className="col-6">
            <Fade
              bottom
              duration={FADE_DURATION}
              delay={TEXT_DELAY}
              distance={FADE_DISTANCE}
            >
              <a
                target="blank"
                rel="noopener noreferrer"
                className="cta-btn cta-btn-contact"
                href={
                  "https://drive.google.com/file/d/1ALRNhRZWsaODXAFNiM4IfBjKTx7Uz9OU/view"
                }
              >
                {"Resume"}
              </a>
            </Fade>
          </Col>
        </Row>
        <div className="social-links">
          <a
            key="1"
            href="https://github.com/VacuusUmbra"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="github"
            className="bounce-up"
          >
            <FaGithub />
          </a>
          <a
            key="2"
            href="https://gitlab.com/asuar"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="gitlab"
            className="bounce-up"
          >
            <SiGitlab />
          </a>
          <a
            key="3"
            href="https://linkedin.com/in/alain-suarez"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="linkedin"
            className="bounce-up"
          >
            <FaLinkedin />
          </a>
          <a
            key="4"
            href="https://twitter.com/AlainSuarezDev"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="twitter"
            className="bounce-up"
          >
            <FaTwitter />
          </a>
        </div>
        <hr />
        <p className="footer__text text-center">
          © {new Date().getFullYear()} - created by{" "}
          <a
            href="https://gitlab.com/asuar/gatsby-alain-portfolio"
            target="_blank"
            rel="noopener noreferrer"
          >
            Alain Suarez
          </a>
        </p>
      </Container>
    </footer>
  )
}

export default Footer

import React from "react"

const Particle = ({ position }) => {
  let num_particles = 60 // same as in scss

  let particles = []
  for (let i = 0; i < num_particles; i++) {
    particles.push(
      <div className={`particle ${position}`} key={"particle-" + i}></div>
    )
  }

  return <div id={`particle-container-${position}`}>{particles}</div>
}

export default Particle
